import axios from 'axios';

export const getManualPaymentList = () => axios.get('/manual_payments/');

export const getManualPaymentById = (id) => axios.get(`/manual_payments/${id}/`);

export const createManualPayment = (data) => axios.post('/manual_payments/', data);

export const updateManualPayment = (id, data) => axios.patch(`/manual_payments/${id}/`, data);

export const refundManualPayment = (id) => axios.put(`/manual_payments/${id}/refund/`);
