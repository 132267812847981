<template>
    <div>
        <LoadingSpinner v-if="loading" class="vh-50" />
        <LoadingError v-else-if="error" class="vh-50" />
        <div v-else class="card">
            <div class="card-header">
                <div class="row align-items-center justify-content-between">
                    <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Payment Report</h5>
                    </div>
                    <div class="col-8 col-sm-auto text-right pl-2">
                        <FileDownloadButton :download-url="`soap/payout_reports/${report.payout.id}/csv_export`"
                                            :file-name="`Payout Report - ${report.payout.id}.csv`"
                                            class="btn btn-sm btn-falcon-default">
                            Export to CSV
                        </FileDownloadButton>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="px-3 pb-3">
                    <div>Date: {{ report.payout.created | formatISODate('MMM d, YYY') }}</div>
                    <div>
                        Type: {{ formattedType }}
                    </div>
                    <div>Amount: {{ report.payout.amount | formatCents }}</div>
                </div>
                <hr>
                <template v-if="report.manual_payments.length > 0">
                    <div class="row justify-content-end mx-0 my-3">
                        <div class="col col-sm-5 col-xxl-4">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   placeholder="Search..."
                                   aria-label="Search"
                                   v-model="search">
                        </div>
                    </div>
                    <PaymentsTable :payments="report.manual_payments" :filter="search" />
                </template>
                <div v-else
                     class="d-flex align-items-center justify-content-center flex-column p-3"
                     style="height: 300px;">
                    <i class="fas fa-money-bill-wave" style="font-size: 2rem;" />
                    <span class="mt-3">No payments associated with this payout.</span>
                    <small class="mt-2 w-50 text-center">
                        Please contact customer support.
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FileDownloadButton from '@/components/FileDownloadButton';
    import { getPayoutReport } from '@/services/PayoutReportService';
    import LoadingError from '@/components/LoadingError';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import PaymentsTable from '@/components/PaymentsTable';

    export default {
        name: 'PayoutReportsDetails',
        components: {
            LoadingSpinner,
            LoadingError,
            PaymentsTable,
            FileDownloadButton,
        },
        data() {
            return {
                loading: true,
                error: null,
                report: null,
                search: '',
            };
        },
        computed: {
            formattedType() {
                const type = this.report.payout.type;
                return type === 'C' ? 'Credit' : type === 'D' ? 'Debit' : 'Unknown';
            },
        },
        async mounted() {
            this.loading = true;
            try {
                const { data } = await getPayoutReport(this.$route.params.id);
                this.report = data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    };
</script>
